import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { withRouter } from "react-router";

class PageModal extends Component {
  render() {
    return (
      <div>
        {this.props.data.showOverlay === 'CARD_NETWORK' && (
          <div className="overlay">
            <div className="overlay__box">
              <div
                className="spinner-border mb-4"
                style={{ color: "#686868" }}
                role="status"
              >
                <span className="sr-only">Loading...</span>
              </div>
              <img className='overlay__logo' alt="" src={require("../img/cardnetwork-2x.png")} />
            </div>
          </div>
        )}
         {this.props.data.showOverlay === 'ICON_ONLY' && (
          <div className="overlay">
            <div className="overlay__box overlay__box--logo">
              <img alt="" className="mt-5 mb-5 overlay__gif" src={require("../img/spinner-FR-I-2x.gif")} />
            </div>
          </div>
        )}
        {this.props.data.showOverlay === 'ICON_TO_LOGO' && (
          <div className="overlay">
            <div className="overlay__box overlay__box--logo">
              <img alt="" className="mt-5 mb-5 overlay__gif" src={require("../img/spinner-FR-IL-2x.gif")} />
            </div>
          </div>
        )}
      </div>
    );
  }
}

const mapStateToProps = ({ data }) => ({
  data,
});

const mapDispatchToProps = (dispatch) => bindActionCreators({}, dispatch);

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(PageModal)
);
