import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import {nextAction} from '../../actions/actions';
const moment = require('moment');


class Q13 extends Component {
  constructor(props) {
    super(props);
    this.state = {
      f1: '',
    };
    this.formChange = this.formChange.bind(this);
  }

  componentDidMount() {
    this.props.setFlowPosition();
  }

  formChange(v, item) {
    let s = this.state;
    s[item] = v;
    this.setState(s);
  }
  render() {
    return (
      <div className="bg-white min-height-100vh">
        <div className="plain-header">
          <h2>Secure checkout</h2>
          <button
            className="btn btn-header-link"
            onClick={() => this.props.history.goBack()}
          >
            Cancel
          </button>
        </div>

        <div className="container pt-0 pb-3">
          <div className="bank-logos">
            <img alt="" src={require("../../img/issuer-2x-bad.png")} />
            <img alt="" src={require("../../img/cardnetwork-2x-bad.png")} />
          </div>
          <div className="yourbank-text pt-3">
            <h1>Payment security</h1>
            <h2>BriteSpark - $99.00</h2>
            <h3>{moment().format('MMMM Do YYYY')}</h3>
            <h3>XXXX XXXX XXXX 0523</h3>

            <p className="pt-3 pb-3">
              Verify this payment with YourBank by entering the following
              information:
            </p>

            <div className="form-group">
              <label className="label--bank" htmlFor="exampleInputEmail1">
                In what town were you born?
              </label>
              <input
                type="text"
                className="form-control form-control--bank"
                id="exampleInputEmail1"
                aria-describedby="emailHelp"
                placeholder=""
                onChange={(e) => this.formChange(e.target.value, "f1")}
                value={this.state.f1}
              />
            </div>
          </div>
          <button
            onClick={(e) => {
              if (!this.state.f1) { e.preventDefault(); return; }
              this.props.nextAction();
            }}
            className="btn btn-primary btn-bank w-100 mt-3 mb-3"
          >
            Submit
          </button>
          <div className="learn-more mt-3">
            <i className="material-icons">arrow_forward_ios</i>Learn more about
            authentication
          </div>
          <div className="learn-more mt-3">
            <i className="material-icons">arrow_forward_ios</i>Need some help?
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({ data }) => ({
  data,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      nextAction,
      setFlowPosition: () => ({ type: "FLOW_POSITION", data:2})
    },
    dispatch
  );

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(Q13)
);
