import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { nextAction } from '../../actions/actions'

class Order extends Component {
  constructor(props) {
    super(props);
    this.state = {
      cardNumber: null,
      date: null,
      oldLength: 0,
    };
    this._handlingCardNumber = this._handlingCardNumber.bind(this);
    this._handlingDateNumber = this._handlingDateNumber.bind(this);
  }

  componentDidMount() {
    this.props.setFlowPosition();
  }

  _handlingCardNumber(number) {
    let v = null;
    if (number.length <= 19) {
      v = number
        .replace(/\s?/g, "")
        .replace(/(\d{4})/g, "$1 ")
        .trim();
    }
    this.setState({ cardNumber: v });
  }

  _handlingDateNumber(text) {
    if (text.length === 2 && this.state.oldLength === 1) {
      // This is where the user has typed 2 numbers so far
      // We can manually add a slash onto the end
      // We check to make sure the current value was only 1 character
      // long so that if they are backspacing, we don't add on the slash again
      text += "/";
    }

    this.setState({ oldLength: text.length });

    this.setState({ date: text });
  }

  render() {
    return (
      <div>
        <div className="plain-header">
          <h2>Place your order</h2>
          <button
            className="btn btn-header-link"
            onClick={() => this.props.history.goBack()}
          >
            Cancel
          </button>
        </div>

        <div className="container header-margin--plain pt-lg-3 pb-lg-3">
          <div className="row pt-3">
            <div className="col-12 col-lg-6 offset-lg-3">
              <div className="box">
                <div className="row">
                  <div className="col-12 mt-0">
                    <h4>Order Details</h4>
                    <div className="row box--prices">
                      <div className="col-2">
                        <img alt=""
                          className="img-fluid"
                          
                          src={require("../../img/slider-speaker.png")}
                          />
                      </div>
                      <div className="col">
                        <h2>Smart Home Speaker</h2>
                        <h3>$99.00</h3>
                      </div>
                    </div>
                    <div className="row mt-3">
                      <div className="col-5 line-a">Dispatch to:</div>
                      <div className="col-4 line-b">Jo Bloggs</div>
                      <div className="col-3 text-right line-c">Change</div>
                    </div>
                    <div className="line-d">29 Westview, Harlington, 112345</div>

                    <div className="row mt-3">
                      <div className="col-5 line-a">Payment method:</div>
                      <div className="col-4 line-b">
                        ends in <b>0523</b>
                      </div>
                      <div className="col-3 text-right line-c">Change</div>
                    </div>
                    <div className="line-d">29 Westview, Harlington, 112345</div>
                    <hr />
                    <div className="line-d">
                      <div className="row">
                        <div className="col">Items:</div>
                        <div className="col text-right">$99.00</div>
                      </div>
                    </div>
                    <div className="line-d">
                      <div className="row">
                        <div className="col">Delivery:</div>
                        <div className="col text-right">$0.00</div>
                      </div>
                    </div>
                    <hr />
                    <div className="line-d">
                      <div className="row">
                        <div className="col">
                          <b>Order Total:</b>
                        </div>
                        <div className="col text-right">
                          <b>$99.00</b>
                        </div>
                      </div>
                    </div>
                    <div className="text-center">
                      <button
                        className="btn btn-primary btn-spark mt-5 mb-3 w-100"
                        onClick={this.props.nextAction}
                      >
                        Buy Now
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({ data }) => ({
  data,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      nextAction,
      setFlowPosition: () => ({ type: "FLOW_POSITION", data:0})

    },
    dispatch
  );

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(Order)
);
