import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { goThanks } from "../actions/actions";
class Q91Modal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      firstRun: true,
    };
    this.thanks = this.thanks.bind(this);
  }

  componentDidMount() {
    setTimeout(() => {
      window.open("https://your-bank.co/authenticate/982741", "_blank");
      this.thanks();
    }, 5000);
  }

  thanks() {
    this.props.goThanks();
    this.props.hideOverlay();
  }

  render() {
    return (
      <div className="overlay">
        <div className="overlay__box overlay__box--q91">
          <div
            className="spinner-border mb-4"
            style={{ color: "#686868" }}
            role="status"
          >
            <span className="sr-only">Loading...</span>
          </div>
          <img
            className="overlay__logo"
            alt=""
            src={require("../img/cardnetwork-2x.png")}
          />
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({ data }) => ({
  data,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    { goThanks, hideOverlay: () => ({ type: "HIDE_OVERLAY" }) },
    dispatch
  );

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(Q91Modal)
);
