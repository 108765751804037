import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { withRouter } from "react-router";

class Flows extends Component {
  render() {
    return (
      <div className="bg-white min-height-100vh">
        <div className="plain-header">
          <h2>Available flows</h2>
        </div>

        <div className="container pt-3 pb-3">
      
          {Object.entries(this.props.data.flow).map((f,i) => {
          console.log(f)
          return ( <p><a href={`/?flow=${f[0]}`}>{f[0]} - {this.props.data.flowNames[f[0]]}</a></p>)
          }
          )}
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({ data }) => ({
  data,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
    },
    dispatch
  );

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(Flows)
);
