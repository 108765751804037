import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { Link } from "react-router-dom";

class Guest1 extends Component {
  render() {
    return (
      <div>
        <div className="plain-header">
          <h2>Thank you</h2>
          <button
            className="btn btn-header-link"
            onClick={() => this.props.history.goBack()}
          >
            Cancel
          </button>
        </div>

        <div className="container header-margin--plain">
          <div className="row">
            <div className="col-12 col-lg-6 offset-lg-3">
              <div className="box success mt-3 text-left">
                <h1>Your purchase is successful</h1>
                <h2>
                  Order number: <span>#8240822</span>
                </h2>
                <p>
                  Thank you for your order, we hope you enjoyed shopping with
                  us.
                </p>
                <Link to="/" className="btn btn-spark btn-primary mt-3 mb-4 w-100">
                  Continue shopping
                </Link>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-12 col-lg-6 offset-lg-3">
              <div className="box mt-3 mb-4">
                <h4>Order Summary</h4>
                <div className="row box--prices">
                  <div className="col-2">
                    <img
                      alt=""
                      className="img-fluid"
                      src={require("../../img/slider-speaker.png")}
                    />
                  </div>
                  <div className="col">
                    <h2>1x Smart Home Speaker</h2>
                    <h3>$99.00</h3>
                  </div>
                </div>

                <div className="row mt-3">
                  <div className="col-5 line-a">Dispatch to:</div>
                  <div className="col-4 line-b">Jo Bloggs</div>
                  <div className="col-3 text-right line-c"></div>
                </div>

                <div className="line-d">29 Westview, Harlington, 112345</div>

                <div className="row mt-3">
                  <div className="col-5 line-a">Payment method:</div>
                  <div className="col-4 line-b">
                    ends in <b>0523</b>
                  </div>
                  <div className="col-3 text-right line-c"></div>
                </div>
                <div className="line-d">
                  Billing address: Same as delivery address
                </div>
                <hr />
                <div className="line-d">
                  <div className="row">
                    <div className="col">Items:</div>
                    <div className="col text-right">$99.00</div>
                  </div>
                </div>
                <div className="line-d">
                  <div className="row">
                    <div className="col">Delivery:</div>
                    <div className="col text-right">$0.00</div>
                  </div>
                </div>
                <hr />
                <div className="line-d">
                  <div className="row">
                    <div className="col">
                      <b>Order Total:</b>
                    </div>
                    <div className="col text-right">
                      <b>$99.00</b>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({ data }) => ({
  data,
});

const mapDispatchToProps = (dispatch) => bindActionCreators({}, dispatch);

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(Guest1)
);
