import { push } from "connected-react-router";
import store from "../store";

export const nextAction = () => (dispatch) => {
  const currentFlow = store.getState().data.currentFlow;
  const currentFlowPosition = store.getState().data.currentFlowPosition;
  const flow = store.getState().data.flow;
  const item = flow[currentFlow][currentFlowPosition];

  if (item.type === "overlay" && item.logoSet !== "Q91") {
    dispatch({ type: "SHOW_OVERLAY", data: item.logoSet });

    setTimeout(() => {
      dispatch({ type: "HIDE_OVERLAY" });
      dispatch({ type: "FLOW_POSITION", data: currentFlowPosition + 1 });
      dispatch(nextAction());
    }, item.duration);

    return;
  }

  if (item.type === "overlay" && item.logoSet === "Q91") {
    dispatch({ type: "SHOW_OVERLAY", data: item.logoSet });
  }

  if (item.type === "spinner") {
    dispatch({ type: "SHOW_SPINNER" });

    setTimeout(() => {
      dispatch({ type: "HIDE_SPINNER" });
      dispatch({ type: "FLOW_POSITION", data: currentFlowPosition + 1 });
      dispatch(nextAction());
    }, item.duration);

    return;
  }

  if (item.type === "page") {
    dispatch(push("/" + item.name));
    dispatch({ type: "FLOW_POSITION", data: currentFlowPosition + 1 });
    return;
  }
};

export const goThanks = () => (dispatch) => {
  console.log('thanks')
  dispatch(push("/thanks"));
};
