import Cookies from 'universal-cookie'
const cookies = new Cookies()

const flowNames= {
  "KBA-B": 'KBA – Baseline Structured',
  "KBA-P": 'KBA – Prose',
  "KBA-AH": 'KBA – Alternate header',
  "KBA-BL": 'KBA – Bad logos',
  "KBA-FT": 'KBA – Formatted text',
  "FR-BS": 'Frictionless – basic spinner – 5 seconds',
  "FR-I": 'Frictionless – icon only – 5 seconds',
  "FR-IL": 'Frictionless – icon to logo – 5 seconds',
  "2FA-1P-NS": '2 Factor – 1 page no scroll',
  "2FA-1P-F": '2 Factor – 1 page failure',
  "2FA-2P": '2 Factor – 2 page',
  "2FA-2P-F": '2 Factor – 2 page failure',
  "KBA-D1": 'KBA – Desktop 1 tab',
  "KBA-D2": 'KBA – Desktop 2 tab',
}

const flow = {
  "KBA-B": [
    { type: "overlay", duration: 5000, logoSet: "CARD_NETWORK" },
    { type: "page", name: "Q10" },
    { type: "spinner", duration: 2000 },
    { type: "page", name: "thanks" },
  ],
  "KBA-P": [
    { type: "overlay", duration: 5000, logoSet: "CARD_NETWORK" },
    { type: "page", name: "Q11" },
    { type: "spinner", duration: 2000 },
    { type: "page", name: "thanks" },
  ],
  "KBA-AH": [
    { type: "overlay", duration: 5000, logoSet: "CARD_NETWORK" },
    { type: "page", name: "Q12" },
    { type: "spinner", duration: 2000 },
    { type: "page", name: "thanks" },
  ],
  "KBA-BL": [
    { type: "overlay", duration: 5000, logoSet: "CARD_NETWORK" },
    { type: "page", name: "Q13" },
    { type: "spinner", duration: 2000 },
    { type: "page", name: "thanks" },
  ],
  "KBA-FT": [
    { type: "overlay", duration: 5000, logoSet: "CARD_NETWORK" },
    { type: "page", name: "Q14" },
    { type: "spinner", duration: 2000 },
    { type: "page", name: "thanks" },
  ],
  "FR-BS": [
    { type: "overlay", duration: 5000, logoSet: "CARD_NETWORK" },
    { type: "page", name: "thanks" },
  ],
  "FR-I": [
    { type: "overlay", duration: 5000, logoSet: "ICON_ONLY" },
    { type: "page", name: "thanks" },
  ],
  "FR-IL": [
    { type: "overlay", duration: 5000, logoSet: "ICON_TO_LOGO" },
    { type: "page", name: "thanks" },
  ],
  "2FA-1P-NS": [
    { type: "overlay", duration: 5000, logoSet: "CARD_NETWORK" },
    { type: "page", name: "Q30" },
    { type: "spinner", duration: 2000 },
    { type: "page", name: "thanks" },
  ],
  "2FA-1P-F": [
    { type: "overlay", duration: 5000, logoSet: "CARD_NETWORK" },
    { type: "page", name: "Q30F" },
    { type: "spinner", duration: 2000 },
    { type: "page", name: "thanks" },
  ],
  "2FA-2P": [
    { type: "overlay", duration: 5000, logoSet: "CARD_NETWORK" },
    { type: "page", name: "Q40" },
    { type: "spinner", duration: 2000 },
    { type: "page", name: "Q41" },
    { type: "spinner", duration: 2000 },
    { type: "page", name: "thanks" },
  ],
  "2FA-2P-F": [
    { type: "overlay", duration: 5000, logoSet: "CARD_NETWORK" },
    { type: "page", name: "Q40" },
    { type: "spinner", duration: 2000 },
    { type: "page", name: "Q41" },
    { type: "spinner", duration: 2000 },
    { type: "page", name: "Q40F" },
    { type: "spinner", duration: 2000 },
    { type: "page", name: "Q41F" },
    { type: "spinner", duration: 2000 },
    { type: "page", name: "thanks" },
  ],
  "KBA-D1": [
    { type: "page", name: "Q90" },
    { type: "page", name: "thanks" },
  ],
  "KBA-D2": [
    { type: "overlay", logoSet: "Q91" },
    { type: "page", name: "thanks" },

  ],
};

const initialState = {
  showOverlay: null,
  showSpinnerOverlay: false,
  currentFlow: cookies.get('flow') || "KBA-D1",
  currentFlowPosition: 0,
  flow: flow,
  flowNames: flowNames,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case "SHOW_OVERLAY":
      return {
        ...state,
        showOverlay: action.data,
      };
    case "HIDE_OVERLAY":
      return {
        ...state,
        showOverlay: null,
      };
    case "SHOW_SPINNER":
      return {
        ...state,
        showSpinnerOverlay: true,
      };
    case "HIDE_SPINNER":
      return {
        ...state,
        showSpinnerOverlay: false,
      };
    case "FLOW_POSITION":
      return {
        ...state,
        currentFlowPosition: action.data,
      };
    case "SET_FLOW":
      return {
        ...state,
        currentFlow: action.data,
        currentFlowPosition: 0,
      };
    default:
      return state;
  }
};
