import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { Link } from "react-router-dom";

class Guest1 extends Component {
  constructor(props) {
    super(props);
    this.state = {
      f1: '',
      f2: '',
      f3: '',
      f4: '',
      f5: '',
    };
    this.formChange = this.formChange.bind(this);
  }

  formChange(v, item) {
    let s = this.state;
    s[item] = v;
    this.setState(s);
  }
  render() {
    return (
      <div>
        <div className="plain-header">
          <h2>Enter a delivery address</h2>
          <button
            className="btn btn-header-link"
            onClick={() => this.props.history.goBack()}
          >
            Cancel
          </button>
        </div>

        <div className="container header-margin--plain pt-lg-3 pb-lg-3">
          <div className="row pt-3 pb-3">
          <div className="col-12 col-lg-6 offset-lg-3">
              <div className="box">
                <div className="row">
                  <div className="col-12 mt-0">
                    <h4>Delivery details</h4>
                    <div className="form-group">
                      <label htmlFor="exampleInputEmail1">Full name</label>
                      <input
                        type="text"
                        className="form-control"
                        id="exampleInputEmail1"
                        aria-describedby="emailHelp"
                        placeholder=""
                        onChange={(e) =>
                          this.formChange(e.target.value, "f1")
                        }
                        value={this.state.f1}
                      />
                    </div>
                    <div className="form-group">
                      <label htmlFor="exampleInputEmail2">Email</label>
                      <input
                        type="email"
                        className="form-control"
                        id="exampleInputEmail2"
                        aria-describedby="emailHelp"
                        placeholder=""
                        onChange={(e) =>
                          this.formChange(e.target.value, "f2")
                        }
                        value={this.state.f2}
                      />
                    </div>
                    <div className="form-group">
                      <label htmlFor="exampleInputEmail3">Address line 1</label>
                      <input
                        type="text"
                        className="form-control"
                        id="exampleInputEmail3"
                        aria-describedby="emailHelp"
                        placeholder=""
                        onChange={(e) =>
                          this.formChange(e.target.value, "f3")
                        }
                        value={this.state.f3}
                      />
                    </div>
                    <div className="form-group">
                      <label htmlFor="exampleInputEmail4">City</label>
                      <input
                        type="text"
                        className="form-control"
                        id="exampleInputEmail4"
                        aria-describedby="emailHelp"
                        placeholder=""
                        onChange={(e) =>
                          this.formChange(e.target.value, "f4")
                        }
                        value={this.state.f4}
                      />
                    </div>
                    <div className="form-group">
                      <label htmlFor="exampleInputEmail5">Zip code</label>
                      <input
                        type="text"
                        className="form-control"
                        id="exampleInputEmail5"
                        aria-describedby="emailHelp"
                        placeholder=""
                        onChange={(e) =>
                          this.formChange(e.target.value, "f5")
                        }
                        value={this.state.f5}
                      />
                    </div>
                    <div className="form-check">
                      <input
                        type="checkbox"
                        className="form-check-input"
                        id="exampleCheck1"
                      />
                      <label className="form-check-label" htmlFor="exampleCheck1">
                        Make billing details same as delivery address
                      </label>
                    </div>

                    <Link
                       onClick={(e) => {
                        if (!this.state.f1 || !this.state.f2 || !this.state.f3 || !this.state.f4 || !this.state.f5 )
                          e.preventDefault();
                      }}
                      className="btn btn-primary btn-spark w-100 mt-5 mb-3"
                      to={'/guest2'}
                    >
                      Continue
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({ data }) => ({
  data,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {

    },
    dispatch
  );

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(Guest1)
);
