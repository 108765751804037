import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { Link } from "react-router-dom";

class Basket extends Component {
  render() {
    return (
      <div>
        <div className="delivery">
          <img alt="" className="van" src={require("../../img/van.png")} /> Free
          delivery on orders over $50
        </div>

        <div className="container header-margin pt-3  pt-lg-3 pb-lg-3">
          <div className="britespark-alert">
            <i className="material-icons mr-2">check</i>
            Smart Home Speaker added to your basket
          </div>

          <div className="row mt-3">
            <div className="col-12 col-lg-8 d-none d-lg-block">
              <div className="box">
                <h4>Items in your basket</h4>
                <div class="row box--prices">
                  <div class="col-2">
                  <img
                      alt=""
                      className="img-fluid"
                      src={require("../../img/slider-speaker.png")}
                    />                  </div>
                  <div class="col">
                    <h2>Smart Home Speaker</h2>
                    <h3>$99.00</h3>
                    <h4>Remove</h4>
                  </div>
                </div>
                <hr />
                <div class="box--sub">Subtotal (1 item): $99.00</div>
              </div>
            </div>
            <div className="col-12 col-lg-4">
              <div className="box">
                <h3>Subtotal: $99.00</h3>
                <hr />
                <div className="row">
                  <div className="col box--items">Items:</div>
                  <div className="col box--price">$99.00</div>
                </div>
                <div className="row">
                  <div className="col box--items">Delivery:</div>
                  <div className="col box--price">$0.00</div>
                </div>
                <hr />
                <div className="row">
                  <div className="col box--items">
                    <b>Order Total:</b>
                  </div>
                  <div className="col box--price">
                    <b>$99.00</b>
                  </div>
                </div>
                <Link
                  className="btn btn-primary btn-spark mt-3 w-100"
                  to={"/checkout"}
                >
                  Proceed to checkout
                </Link>
              </div>
            </div>
          </div>

          <h5 className="mt-3">Trending products today</h5>
          <div className="row">
            <div className="col-6 col-lg-3">
              <div className="trend mb-4">
                <div className="trend--image-holder">
                  <img
                    alt=""
                    className="trend--image"
                    src={require("../../img/image-0.png")}
                  />
                </div>
                <div className="trend--text">
                  <b>SoundLink II Headphones</b>
                  <span className="mt-1">$129.00</span>
                </div>
              </div>
            </div>

            <div className="col-6 col-lg-3">
              <div className="trend mb-4">
                <div className="trend--image-holder">
                  <img
                    alt=""
                    className="trend--image"
                    src={require("../../img/image-1.png")}
                  />
                </div>
                <div className="trend--text">
                  <b>Gaming Laptop</b>
                  <span className="mt-1">$399.00</span>
                </div>
              </div>
            </div>

            <div className="col-6 col-lg-3">
              <div className="trend mb-4">
                <div className="trend--image-holder">
                  <img
                    alt=""
                    className="trend--image"
                    src={require("../../img/image-2.png")}
                  />
                </div>
                <div className="trend--text">
                  <b>Smart Home Speaker</b>
                  <span className="mt-1">$99.00</span>
                </div>
              </div>
            </div>

            <div className="col-6 col-lg-3">
              <div className="trend mb-4">
                <div className="trend--image-holder">
                  <img
                    alt=""
                    className="trend--image"
                    src={require("../../img/image-3.png")}
                  />
                </div>
                <div className="trend--text">
                  <b>Large Blender</b>
                  <span className="mt-1">$39.99</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({ data }) => ({
  data,
});

const mapDispatchToProps = (dispatch) => bindActionCreators({}, dispatch);

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(Basket)
);
