import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { withRouter } from "react-router";

class SpinnerModal extends Component {
  render() {
    return (
      <div>
        {this.props.data.showSpinnerOverlay && (
          <div className="overlay-spinner">
          
              <div
                className="spinner-border mb-4"
                style={{ color: "#686868" }}
                role="status"
              >
                <span className="sr-only">Loading...</span>
              </div>
         
            </div>
        )}
      </div>
    );
  }
}

const mapStateToProps = ({ data }) => ({
  data,
});

const mapDispatchToProps = (dispatch) => bindActionCreators({}, dispatch);

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(SpinnerModal)
);
