import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { withRouter } from "react-router";

class FakeSMS extends Component {
  render() {
    return (
      <div className="fake-sms">
        <div className="fake-sms__top-row">
          <div>
            <img
              alt=""
              className="fake-sms__icon"
              src={require("../img/sms.png")}
            />
            <span>MESSAGES</span>
          </div>
          <div>now</div>
        </div>
        <div className="fake-sms__main-row mt-2">
          <h1>YourBank</h1>
          <h2>
            Your security code is: {Math.floor(100000 + Math.random() * 900000)}
            . Your code will expire in 5 minutes.
          </h2>
        </div>
        {/* <Sound
          url="/iphone-ding-sound.mp3"
          playStatus={Sound.status.PLAYING}
          autoLoad={true}
        /> */}
      </div>
    );
  }
}

const mapStateToProps = ({ data }) => ({
  data,
});

const mapDispatchToProps = (dispatch) => bindActionCreators({}, dispatch);

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(FakeSMS)
);
