import React, { Component } from 'react'
import Routes from '../routes/routes'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import { ToastContainer } from 'react-toastify'
import PageModal from '../components/PageModal'
import SpinnerModal from '../components/SpinnerModal'
import Q91Modal from '../components/Q91Modal'

import PropTypes from 'prop-types'
import { bindActionCreators } from "redux";
import Cookies from 'universal-cookie'
const cookies = new Cookies()

class App extends Component {

  componentDidMount() {
    if (this.props.location.search && this.props.location.search.toLowerCase().includes('?flow=')) {
      const code = this.props.location.search.toLowerCase().replace('?flow=','').toUpperCase();
      // check code exists
      if (this.props.data.flow[code] === undefined) {
        console.log('Flow not recognised');
      } else {
        this.props.setFlow(code)
        cookies.set('flow', code);
      }

    }
  }

  render () {
    return (
      <div>
        <ToastContainer />
        <PageModal />
        <SpinnerModal />
        {this.props.data.showOverlay === "Q91" &&
          <Q91Modal />
        }
        <Routes location={this.props.location} />
      </div>
    )
  }
}

App.propTypes = {
  location: PropTypes.object.isRequired
}



const mapStateToProps = ({data}) => ({
  data,
})

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      setFlow: (code) => ({type:'SET_FLOW', data: code })
    },
    dispatch
  );

  export default withRouter(
    connect(
      mapStateToProps,
      mapDispatchToProps
    )(App)
  );