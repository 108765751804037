import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { nextAction } from "../../actions/actions";
const moment = require("moment");

class Q10 extends Component {
  constructor(props) {
    super(props);
    this.state = {
      f1: "",
      step: 0,
    };
    this.formChange = this.formChange.bind(this);
  }

  componentDidMount() {
    this.props.setFlowPosition();
    setTimeout(() => {
      this.setState({ step: 1 });
    }, 5000);
  }

  formChange(v, item) {
    let s = this.state;
    s[item] = v;
    this.setState(s);
  }
  render() {
    return (
      <div>
        <div className="plain-header">
          <h2>Secure checkout</h2>
          <button
            className="btn btn-header-link"
            onClick={() => this.props.history.goBack()}
          >
            Cancel
          </button>
        </div>

        <div  className="container pt-3 pb-3">
          <div className="row pt-3">
            <div className="col-12 col-md-7">
              <div className="box desktop-box-1">
                <div className="row">
                  <div className="col-12 mt-0">
                    <h4>Order Details</h4>
                    <div className="row box--prices">
                      <div className="col-2">
                        <img
                          alt=""
                          className="img-fluid"
                          src={require("../../img/slider-speaker.png")}
                        />
                      </div>
                      <div className="col">
                        <h2>Smart Home Speaker</h2>
                        <h3>$99.00</h3>
                      </div>
                    </div>
                    <div className="row mt-3">
                      <div className="col-5 line-a">Dispatch to:</div>
                      <div className="col-4 line-b">Jo Bloggs</div>
                      <div className="col-3 text-right line-c">Change</div>
                    </div>
                    <div className="line-d">
                      29 Westview, Harlington, 112345
                    </div>

                    <div className="row mt-3">
                      <div className="col-5 line-a">Payment method:</div>
                      <div className="col-4 line-b">
                        ends in <b>0523</b>
                      </div>
                      <div className="col-3 text-right line-c">Change</div>
                    </div>
                    <div className="line-d">
                      29 Westview, Harlington, 112345
                    </div>
                    <hr />
                    <div className="line-d">
                      <div className="row">
                        <div className="col">Items:</div>
                        <div className="col text-right">$99.00</div>
                      </div>
                    </div>
                    <div className="line-d">
                      <div className="row">
                        <div className="col">Delivery:</div>
                        <div className="col text-right">$0.00</div>
                      </div>
                    </div>
                    <hr />
                    <div className="line-d">
                      <div className="row">
                        <div className="col">
                          <b>Order Total:</b>
                        </div>
                        <div className="col text-right">
                          <b>$99.00</b>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-12 col-md-5">
              <div className="box desktop-box-2">
                {this.state.step === 2 && (
                  <div className="desktop-box-spinner">
                    <div
                      className="spinner-border mb-4"
                      style={{ color: "#686868" }}
                      role="status"
                    >
                      <span className="sr-only">Loading...</span>
                    </div>
                  </div>
                )}
                <div className="row">
                  <div className="col-12">
                    {this.state.step === 0 && (
                      <div className="desktop-spinner">
                        <div
                          className="spinner-border mb-4"
                          style={{ color: "#686868" }}
                          role="status"
                        >
                          <span className="sr-only">Loading...</span>
                        </div>
                        <img
                          className="overlay__logo"
                          alt=""
                          src={require("../../img/cardnetwork-2x.png")}
                        />
                      </div>
                    )}
                    {(this.state.step === 1 ||
                      this.state.step === 2) && (
                        <div className="">
                          <div className="bank-logos bank-logos--desktop">
                            <img
                              alt=""
                              src={require("../../img/issuer-2x.png")}
                            />
                            <img
                              alt=""
                              src={require("../../img/cardnetwork-2x.png")}
                            />
                          </div>
                          <div className="yourbank-text pt-3">
                            <h1>Payment security</h1>
                            <h2>BriteSpark - $99.00</h2>
                            <h3>{moment().format("MMMM Do YYYY")}</h3>
                            <h3>XXXX XXXX XXXX 0523</h3>

                            <p className="pt-3 pb-3">
                              Verify this payment with YourBank by entering the
                              following information:
                            </p>

                            <div className="form-group">
                              <label
                                className="label--bank"
                                htmlFor="exampleInputEmail1"
                              >
                                In what town were you born?
                              </label>
                              <input
                                type="text"
                                className="form-control form-control--bank"
                                id="exampleInputEmail1"
                                aria-describedby="emailHelp"
                                placeholder=""
                                onChange={(e) =>
                                  this.formChange(e.target.value, "f1")
                                }
                                value={this.state.f1}
                              />
                            </div>
                          </div>
                          <button
                            onClick={(e) => {
                              if (!this.state.f1) {
                                e.preventDefault();
                                return;
                              }
                              this.setState({step: 2})
                              setTimeout(() => {
                                console.log('next action please');
                                this.props.nextAction();
                              }, 2000);
                            }}
                            className="btn btn-primary btn-bank w-100 mt-3 mb-3"
                          >
                            Submit
                          </button>
                          <div className="learn-more mt-3">
                            <i className="material-icons">arrow_forward_ios</i>
                            Learn more about authentication
                          </div>
                          <div className="learn-more mt-3">
                            <i className="material-icons">arrow_forward_ios</i>
                            Need some help?
                          </div>
                        </div>
                      )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({ data }) => ({
  data,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      nextAction,
      setFlowPosition: () => ({ type: "FLOW_POSITION", data: 1 }),
    },
    dispatch
  );

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(Q10)
);
