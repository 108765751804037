import React from 'react'
import { Switch } from 'react-router-dom'
import { Route } from 'react-router'
import PropTypes from 'prop-types'
import Layout from '../containers/Layout'
import Home from '../containers/Screens/Home'
import More from '../containers/Screens/More'
import Basket from '../containers/Screens/Basket'
import Checkout from '../containers/Screens/Checkout'
import LayoutPlain from '../containers/LayoutPlain.jsx'
import Guest1 from '../containers/Screens/Guest1'
import Guest2 from '../containers/Screens/Guest2'
import Order from '../containers/Screens/Order'
import Thanks from '../containers/Screens/Thanks'
import Q10 from '../containers/Screens/Q10'
import Q11 from '../containers/Screens/Q11'
import Q12 from '../containers/Screens/Q12'
import Q13 from '../containers/Screens/Q13'
import Q14 from '../containers/Screens/Q14'
import Q30 from '../containers/Screens/Q30'
import Q30F from '../containers/Screens/Q30F'
import Q40 from '../containers/Screens/Q40'
import Q41 from '../containers/Screens/Q41'
import Q40F from '../containers/Screens/Q40F'
import Q41F from '../containers/Screens/Q41F'
import Q90 from '../containers/Screens/Q90'

import Flows from '../containers/Screens/Flows'



const Routes = (props) => (
  <Switch location={props.location} >
    <Route exact path='/' render={(props) => <Layout location={props.location}><Home /></Layout>} />
    <Route exact path='/more' render={(props) => <Layout location={props.location}><More /></Layout>} />
    <Route exact path='/basket' render={(props) => <Layout location={props.location}><Basket /></Layout>} />
    <Route exact path='/checkout' render={(props) => <LayoutPlain location={props.location}><Checkout /></LayoutPlain>} />
    <Route exact path='/guest1' render={(props) => <LayoutPlain location={props.location}><Guest1 /></LayoutPlain>} />
    <Route exact path='/guest2' render={(props) => <LayoutPlain location={props.location}><Guest2 /></LayoutPlain>} />
    <Route exact path='/order' render={(props) => <LayoutPlain location={props.location}><Order /></LayoutPlain>} />
    <Route exact path='/thanks' render={(props) => <LayoutPlain location={props.location}><Thanks /></LayoutPlain>} />
    <Route exact path='/q10' render={(props) => <LayoutPlain location={props.location}><Q10 /></LayoutPlain>} />
    <Route exact path='/q11' render={(props) => <LayoutPlain location={props.location}><Q11 /></LayoutPlain>} />
    <Route exact path='/q12' render={(props) => <LayoutPlain location={props.location}><Q12 /></LayoutPlain>} />
    <Route exact path='/q13' render={(props) => <LayoutPlain location={props.location}><Q13 /></LayoutPlain>} />
    <Route exact path='/q14' render={(props) => <LayoutPlain location={props.location}><Q14 /></LayoutPlain>} />
    <Route exact path='/q30' render={(props) => <LayoutPlain location={props.location}><Q30 /></LayoutPlain>} />
    <Route exact path='/q30f' render={(props) => <LayoutPlain location={props.location}><Q30F /></LayoutPlain>} />
    <Route exact path='/q40' render={(props) => <LayoutPlain location={props.location}><Q40 /></LayoutPlain>} />
    <Route exact path='/q41' render={(props) => <LayoutPlain location={props.location}><Q41 /></LayoutPlain>} />
    <Route exact path='/q40f' render={(props) => <LayoutPlain location={props.location}><Q40F /></LayoutPlain>} />
    <Route exact path='/q41f' render={(props) => <LayoutPlain location={props.location}><Q41F /></LayoutPlain>} />
    <Route exact path='/q90' render={(props) => <LayoutPlain location={props.location}><Q90 /></LayoutPlain>} />
    <Route exact path='/flows' render={(props) => <LayoutPlain location={props.location}><Flows /></LayoutPlain>} />
  </Switch>

)

Routes.propTypes = {
  location: PropTypes.object
}

export default Routes
