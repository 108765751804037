import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { Link } from "react-router-dom";

class Home extends Component {
  render() {
    return (
      <div>
        <div className="delivery">
          <img alt="" className="van" src={require("../../img/van.png")} /> Free
          delivery on orders over $50
        </div>
        <div className="container container--no-padding">
          <div className="pt-0 pt-lg-3 pl-lg-3 pr-lg-3">
            <div className="banner">
              <img
                alt=""
                className="banner--bg"
                src={require("../../img/rectangle.png")}
              />
              <div className="banner--text">
                <h1>Your new Smart Home Speaker</h1>
                <h2>Only $99.00</h2>
                <Link to={"/more"} className="btn btn-secondary btn-spark">
                  Find Out More
                </Link>
              </div>
            </div>
          </div>
          <div className="container">
            <h3 className="mt-3">Product categories</h3>

            <div className="row">
              <div className="col-6 col-lg-3">
                <div className="product mb-4">
                  <div className="product--image-holder">
                    <img
                      alt=""
                      className="product--image"
                      src={require("../../img/image-4.png")}
                    />
                  </div>
                  <div className="product--text">Audio & Visual</div>
                </div>
              </div>

              <div className="col-6 col-lg-3">
                <div className="product mb-4">
                  <div className="product--image-holder">
                    <img
                      alt=""
                      className="product--image"
                      src={require("../../img/image-7.png")}
                    />
                  </div>
                  <div className="product--text">Computing</div>
                </div>
              </div>

              <div className="col-6 col-lg-3">
                <div className="product mb-4">
                  <div className="product--image-holder">
                    <img
                      alt=""
                      className="product--image"
                      src={require("../../img/image-6.png")}
                    />
                  </div>
                  <div className="product--text">Large appliances</div>
                </div>
              </div>

              <div className="col-6 col-lg-3">
                <div className="product mb-4">
                  <div className="product--image-holder">
                    <img
                      alt=""
                      className="product--image"
                      src={require("../../img/image-5.png")}
                    />
                  </div>
                  <div className="product--text">Small appliances</div>
                </div>
              </div>
            </div>

            <h3 className="mt-3">Trending products today</h3>
            <div className="row">
              <div className="col-6 col-lg-3">
                <div className="trend mb-4">
                  <div className="trend--image-holder">
                    <img
                      alt=""
                      className="trend--image"
                      src={require("../../img/image-0.png")}
                    />
                  </div>
                  <div className="trend--text">
                    <b>SoundLink II Headphones</b>
                    <span className="mt-1">$129.00</span>
                  </div>
                </div>
              </div>

              <div className="col-6 col-lg-3">
                <div className="trend mb-4">
                  <div className="trend--image-holder">
                    <img
                      alt=""
                      className="trend--image"
                      src={require("../../img/image-1.png")}
                    />
                  </div>
                  <div className="trend--text">
                    <b>Gaming Laptop</b>
                    <span className="mt-1">$399.00</span>
                  </div>
                </div>
              </div>

              <div className="col-6 col-lg-3">
              
              <Link to={"/more"} className="trend mb-4">
                  <div className="trend--image-holder">
                    <img
                      alt=""
                      className="trend--image"
                      src={require("../../img/image-2.png")}
                    />
                  </div>
                  
                  <div className="trend--text">
                    <b>Smart Home Speaker</b>
                    <span className="mt-1">$99.00</span>
                  </div>
                </Link>
              </div>

              <div className="col-6 col-lg-3">
                <div className="trend mb-4">
                  <div className="trend--image-holder">
                    <img
                      alt=""
                      className="trend--image"
                      src={require("../../img/image-3.png")}
                    />
                  </div>
                  <div className="trend--text">
                    <b>Large Blender</b>
                    <span className="mt-1">$39.99</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({ data }) => ({
  data,
});

const mapDispatchToProps = (dispatch) => bindActionCreators({}, dispatch);

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(Home)
);
