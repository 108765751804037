import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { withRouter } from "react-router";
import logo from "../img/britespark.png";
class Layout extends Component {
  render() {
    return (
      <div>
          <nav className="navbar fixed-top navbar-dark bg-spark">
            <div className="nav--holder">
              <i className="material-icons menu">menu</i>
              <a className="navbar-brand" href="/">
                <img alt="" className="logo" src={logo} />
              </a>
            </div>
            <div className="nav--right">
              <i className="material-icons">person_outline</i>
              <i className="material-icons">search</i>
              <i className="material-icons">shopping_cart</i>
            </div>
          </nav>
        
        <div className="main-content">{this.props.children}</div>
        <div className="footer">
          <div className="container">
            <div className="footer--links">
              <span>Delivery</span>
              <span>Returns</span>
              <span>About BriteSpark</span>
              <span>Customer service</span>
            </div>
          </div>
          <hr />
          <div className="container">
            <div className="footer--address">
              <p>
                © BriteSpark Retail Limited. BriteSpark Ltd, 1 Sparkylane, W3
                600, USA.
              </p>
              <p>Company registration number: 10258X</p>
              <p>Terms & conditions</p>
            </div>
            <div className="text-right"></div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({ data }) => ({
  data,
});

const mapDispatchToProps = (dispatch) => bindActionCreators({}, dispatch);

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(Layout)
);
