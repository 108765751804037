import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { Link } from "react-router-dom";

class Checkout extends Component {
  constructor(props) {
    super(props);
    this.state = {
      password: '',
      email: '',
    };
    this.formChange = this.formChange.bind(this);
  }

  formChange(v, item) {
    let s = this.state;
    s[item] = v;
    this.setState(s);
  }

  render() {
    return (
      <div>
        <div className="plain-header">
          <h2>Login</h2>
          <button
            className="btn btn-header-link"
            onClick={() => this.props.history.goBack()}
          >
            Cancel
          </button>
        </div>

        <div className="container header-margin--plain pt-lg-3 pb-lg-3">
          <div className="row pt-3">
          <div className="col-12 col-lg-6 offset-lg-3">
              <div className="box">
                <div className="row">
                  <div className="col-12 mt-0">
                    <h4>Login to account</h4>
                    <div className="form-group">
                      <label htmlFor="exampleInputEmail1">Email</label>
                      <input
                        type="email"
                        onChange={(e) =>
                          this.formChange(e.target.value, "email")
                        }
                        value={this.state.email}
                        className="form-control"
                        id="exampleInputEmail1"
                        aria-describedby="emailHelp"
                        placeholder=""
                      />
                    </div>
                    <div className="form-group">
                      <label htmlFor="exampleInputEmail2">Password</label>
                      <input
                        type="password"
                        onChange={(e) =>
                          this.formChange(e.target.value, "password")
                        }
                        value={this.state.password}
                        className="form-control"
                        id="exampleInputEmail2"
                        aria-describedby="emailHelp"
                        placeholder=""
                      />
                    </div>
                    <Link
                      onClick={(e) => {
                        if (!this.state.email || !this.state.password)
                          e.preventDefault();
                      }}
                      className="btn btn-primary btn-spark w-100 mt-3 mb-3"
                      to={"order"}
                    >
                      Login
                    </Link>
                  </div>
                </div>
                <hr />
                <Link className="btn btn-link w-100" to={"/guest1"}>
                  Continue As Guest
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({ data }) => ({
  data,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {

    },
    dispatch
  );

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(Checkout)
);
