import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { Link } from "react-router-dom";

class Guest2 extends Component {
  constructor(props) {
    super(props);
    this.state = {
      cardNumber: null,
      date: null,
      oldLength: 0,
    };
    this._handlingCardNumber = this._handlingCardNumber.bind(this);
    this._handlingDateNumber = this._handlingDateNumber.bind(this);
    this.formChange = this.formChange.bind(this);
  }

  formChange(v, item) {
    let s = this.state;
    s[item] = v;
    this.setState(s);
  }

  _handlingCardNumber(number) {
    let v = null;
    if (number.length <= 19) {
      v = number
        .replace(/\s?/g, "")
        .replace(/(\d{4})/g, "$1 ")
        .trim();
    }
    this.setState({ cardNumber: v });
  }

  _handlingDateNumber(text) {
    if (text.length === 2 && this.state.oldLength === 1) {
      // This is where the user has typed 2 numbers so far
      // We can manually add a slash onto the end
      // We check to make sure the current value was only 1 character
      // long so that if they are backspacing, we don't add on the slash again
      text += "/";
    }

    this.setState({ oldLength: text.length });

    this.setState({ date: text });
  }

  render() {
    return (
      <div>
        <div className="plain-header">
          <h2>Enter a delivery address</h2>
          <button
            className="btn btn-header-link"
            onClick={() => this.props.history.goBack()}
          >
            Cancel
          </button>
        </div>

        <div className="container header-margin--plain pt-lg-3 pb-lg-3">
          <div className="row pt-3">
          <div className="col-12 col-lg-6 offset-lg-3">
              <div className="box">
                <div className="row">
                  <div className="col-12 mt-0">
                    <h4>Payment Method</h4>
                    <div className="form-group">
                      <label htmlFor="exampleInputEmail1">Card number</label>
                      <input
                        type="text"
                        value={this.state.cardNumber}
                        onChange={(e) =>
                          this._handlingCardNumber(e.target.value)
                        }
                        className="form-control"
                        maxlength="19"
                        id="card"
                        aria-describedby="emailHelp"
                        placeholder=""
                      />
                    </div>
                    <div className="form-group">
                      <label htmlFor="exampleInputEmail2">Name on card</label>
                      <input
                        type="text"
                        className="form-control"
                        id="exampleInputEmail2"
                        onChange={(e) => this.formChange(e.target.value, "f1")}
                        value={this.state.f1}
                        aria-describedby="emailHelp"
                        placeholder=""
                      />
                    </div>
                    <div className="form-group">
                      <label htmlFor="exampleInputEmail3">
                        Expiration date (MM/YY)
                      </label>
                      <input
                        type="text"
                        value={this.state.date}
                        className="form-control"
                        onChange={(e) =>
                          this._handlingDateNumber(e.target.value)
                        }
                        aria-describedby="emailHelp"
                        id="date"
                        maxlength="5"
                      />
                    </div>
                    <div className="form-group">
                      <label htmlFor="exampleInputEmail3">Security code</label>
                      <input
                        type="password"
                        className="form-control"
                        id="exampleInputEmail1"
                        aria-describedby="emailHelp"
                        placeholder=""
                        onChange={(e) => this.formChange(e.target.value, "f2")}
                        value={this.state.f2}
                      />
                    </div>

                    <Link
                      onClick={(e) => {
                        if (
                          !this.state.f1 ||
                          !this.state.f2 ||
                          !this.state.date ||
                          !this.state.cardNumber
                        )
                          e.preventDefault();
                      }}
                      className="btn btn-primary btn-spark w-100 mt-5 mb-3"
                      to={"/order"}
                    >
                      Continue
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({ data }) => ({
  data,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {

    },
    dispatch
  );

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(Guest2)
);
