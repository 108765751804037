import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { Link } from "react-router-dom";

class More extends Component {
  render() {
    return (
      <div>
        <div className="delivery">
          <img alt="" className="van" src={require("../../img/van.png")} /> Free
          delivery on orders over $50
        </div>

        <div className="container container--no-padding pt-lg-3 pb-lg-3">
          <div className="about p-3">
            <div class="row">
              <div class="col-12 col-lg-6">
                <div className="row">
                  <div className="col-12">
                    <img
                      alt=""
                      className="img-fluid"
                      src={require("../../img/slider-speaker.png")}
                    />
                  </div>
                </div>
                <div className="row mt-2">
                  <div className="col-12 thumbnail-row">
                    <img alt="" src={require("../../img/slider-thumb-2.png")} />
                    <img alt="" src={require("../../img/slider-thumb-3.png")} />
                    <img alt="" src={require("../../img/slider-thumb-1.png")} />
                  </div>
                </div>
              </div>
              <div className="col-12 col-lg-6">
                <div className="row mt-3">
                  <div className="col-12">
                    <h3>Smart Home Speaker</h3>
                    <h4>$99.00</h4>
                    <Link
                      className="btn btn-primary btn-spark mt-3 w-100 d-lg-none"
                      to={"/basket"}
                    >
                      Add To Basket
                    </Link>
                  </div>
                </div>

                <div className="row">
                  <div className="col-12 pt-3">
                    <h5>Product information</h5>

                    <p>
                      Smart Home Speaker is powered by the Smart Assistant that
                      helps you turn your home into a smart home. Using just
                      your voice you can talk to Smarty to get answers to the
                      questions you want including the latest on the weather,
                      traffic updates, finance, sports news and more. The
                      far-field microphones pick up your voice reliably.
                    </p>
                    <Link
                      className="btn btn-primary btn-spark mt-3 w-50 d-none d-lg-block"
                      to={"/basket"}
                    >
                      Add To Basket
                    </Link>
                    <hr />
                    <h5>30 day return period</h5>
                    <p>
                      If you’re not satisfied with your purchase you can pack it
                      up safely and return it within 30 days for a full refund.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="d-none d-lg-block">
            <h5 className="mt-4">You might also like</h5>
            <div className="row">
              <div className="col-6 col-lg-3">
                <div className="trend mb-4">
                  <div className="trend--image-holder">
                    <img
                      alt=""
                      className="trend--image"
                      src={require("../../img/image-0.png")}
                    />
                  </div>
                  <div className="trend--text">
                    <b>SoundLink II Headphones</b>
                    <span className="mt-1">$129.00</span>
                  </div>
                </div>
              </div>

              <div className="col-6 col-lg-3">
                <div className="trend mb-4">
                  <div className="trend--image-holder">
                    <img
                      alt=""
                      className="trend--image"
                      src={require("../../img/image-1.png")}
                    />
                  </div>
                  <div className="trend--text">
                    <b>Gaming Laptop</b>
                    <span className="mt-1">$399.00</span>
                  </div>
                </div>
              </div>

              <div className="col-6 col-lg-3">
                <div className="trend mb-4">
                  <div className="trend--image-holder">
                    <img
                      alt=""
                      className="trend--image"
                      src={require("../../img/image-2.png")}
                    />
                  </div>
                  <div className="trend--text">
                    <b>Smart Home Speaker</b>
                    <span className="mt-1">$99.00</span>
                  </div>
                </div>
              </div>

              <div className="col-6 col-lg-3">
                <div className="trend mb-4">
                  <div className="trend--image-holder">
                    <img
                      alt=""
                      className="trend--image"
                      src={require("../../img/image-3.png")}
                    />
                  </div>
                  <div className="trend--text">
                    <b>Large Blender</b>
                    <span className="mt-1">$39.99</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({ data }) => ({
  data,
});

const mapDispatchToProps = (dispatch) => bindActionCreators({}, dispatch);

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(More)
);
