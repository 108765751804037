import { combineReducers } from 'redux'
import data from './dataReducers'
const appReducer = combineReducers({
  data
})

const rootReducer = (state, action) => {
  // set root state to null
  if (action.type === 'LOGOUT') {
    return {
      state: null
    }
  }

  return appReducer(state, action)
}

export default rootReducer
